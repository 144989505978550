import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/camera-wall',
    name: 'camera-wall',
    meta: {
      can: 'read camera wall'
    },
    component: () => import(/* webpackChunkName: "cameraWall" */ '@/views/camera-wall/CameraWall.vue')
  },
])
