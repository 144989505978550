import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('roles', {
  resource () {
    return {
      id: mapped(null),
      title: mapped(null),
      name: mapped(null),
      abilities: mapped([])
    }
  }
})
