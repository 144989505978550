<template>
  <div id="app">
    <router-view class="container flex-grow-1 d-flex align-items-center"/>
  </div>
</template>

<script>
export default {
}
</script>
