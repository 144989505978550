<template>
  <div id="app">
    <div id="wrapper">
      <sidebar/>

      <div id="page-content-wrapper">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/app/AppSidebar'

export default {
  components: {
    Sidebar
  }
}
</script>
