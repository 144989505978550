import { extend, localize, configure } from 'vee-validate'
import { size } from 'vee-validate/dist/rules'
import dayjs from 'dayjs'

export default () => {
  configure({
    mode: 'eager',
    classes: {
      valid: 'is-valid',
      invalid: 'is-invalid'
    }
  })

  extend('size', size)

  extend('password', {
    validate: value => value.length >= 8,
    message: 'The {_field_} must be at least 8 characters'
  })

  extend('contains_special_character', {
    validate: value => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
    message: 'The {_field_} must contain a special characater'
  })

  extend('contains_number', {
    validate: value => /\d/.test(value),
    message: 'The {_field_} must contain a number'
  })

  extend('date_after', {
    validate: (value, args) => dayjs(value).isSameOrAfter(dayjs(args.date)),
    message: (fieldName, args) => {
      if (args.date) {
        return `The ${fieldName} must be after ${dayjs(args.date).format('DD/MM/YYYY')}`
      }
      return `The ${fieldName} must be after date`
    },
    params: ['date']
  })

  localize({
    en: {
      fields: {
        password: {
          is: 'The passwords you have entered do not match'
        }
      }
    }
  })
}
