import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import registerBeforeHooks from './before'

Vue.use(Router)

const router = new Router({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior () {
    Vue.nextTick(() => {
      const el = document.getElementById('page-content-wrapper')

      if (el) {
        el.scrollTo(0, 0)
      }
    })

    return {
      selector: '#page-content-wrapper',
      offset: {
        x: 0,
        y: 0
      }
    }
  }
})

registerBeforeHooks(router)

export default router
