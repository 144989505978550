import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import pathify from 'vuex-pathify'
import abilityPlugin from './plugins/ability'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    abilityPlugin,
    pathify.plugin
  ],
  modules
})
