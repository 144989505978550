<template>
  <component key="template" :is="templateComponent"/>
</template>

<script>
import Main from './templates/Main'
import Auth from './templates/Auth'
import Error from './templates/Error'
import Blank from './templates/Blank'

export default {
  computed: {
    templateComponent () {
      switch (this.$route.meta.template) {
        case 'auth':
          return Auth
        case 'error':
          return Error
        case 'app':
          return Main
        default:
          return Blank
      }
    }
  }
}

</script>
