import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('settings/screenTags', {
  resource () {
    return {
      id: null,
      name: mapped(null),
      archived: null
    }
  }
})
