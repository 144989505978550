import { makeResource, mapped } from '@codium/codium-vue'
import { http } from '@/utils'
import { cloneDeep } from 'lodash'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('users', {
  storeModule: {
    actions: {
      async updateTwoFactor ({ dispatch, getters }, { id, payload }) {
        const response = await http.put(`${getters.uri(id)}/two-factor`, payload)

        dispatch('setUser', response.data.data)

        return response.data.data
      },

      async updateUser ({ dispatch, state, getters }, { id, payload }) {
        const data = new FormData()
        Object.keys(payload).forEach((key) => {
          data.append(key, payload[key])
        })

        const response = await http.post(getters.uri(`${id}`), data)

        dispatch('setUser', response.data.data)
        dispatch('replaceUser', state.user)

        return cloneDeep(state.user)
      },

      async deleteProfileImage ({ dispatch, state, getters }) {
        await http.delete(`${getters.uri(':id')}/profile-image`)
        dispatch('setUser', { ...state.user, profile_image: null })
      },

      async toggleStatus ({ dispatch, state, getters }) {
        const response = await http.put(`${getters.uri(':id')}/toggle-status`)
        dispatch('setUser', response.data.data)
      },
    }
  },

  resource () {
    return {
      id: mapped(null),
      first_name: mapped(null),
      last_name: mapped(null),
      name: mapped(null),
      email: mapped(null),
      mobile: mapped(null),
      status: mapped(null),
      abilities: mapped(null),
      role: mapped(null),
      last_login_at: mapped(null),
      last_login_ip: mapped(null),
      two_factor_required: mapped(null),
      two_factor_method_preferred: mapped(null),
      new_email: mapped(null),
      profile_photo: mapped(null),
      media_owner_id: mapped(null),
      media_owner: mapped(null)
    }
  }
})
