<template>
  <header class="page-header">
    <div class="left">
      <button class="btn" id="menu-toggle" @click="toggleSidebar">
        <i class="fal fa-bars"></i>
      </button>
    </div>
    <div class="middle">
      <div class="page-title">
        <h1>
          {{ title }}
        </h1>
      </div>

      <div class="actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="right">
      <ul class="header-nav justify-content-end">
        <li>
          <button
            type="button"
            class="btn btn-sidesheet-show navbar-user-link d-xl-none"
          >
            <i class="fal fa-columns"></i>
          </button>
        </li>
        <li>
          <c-dropdown>
            <template #button>
              <button class="btn btn-account">
                <img v-if="$user.profile_image" :src="$user.profile_image.url" class="avatar" />
                <div v-else class="user-avatar">
                  <div class="avatar">
                    <span class="initials">{{ $user.first_name.charAt(0) | capitalise }}{{ $user.last_name.charAt(0) | capitalise }}</span>
                  </div>
                </div>
                <i class="far fa-chevron-down"></i>
              </button>
            </template>

            <router-link :to="{ name: 'my.account' }" class="dropdown-item">
              <i class="far fa-fw fa-user-circle"></i> My Account
            </router-link>
            <router-link
              class="dropdown-item"
              :to="{ name: 'logout' }"
            >
              <i class="far fa-fw fa-sign-out"></i> Logout
            </router-link>
          </c-dropdown>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { appComputed } from '@/vuex/helpers'

export default {
  props: {
    title: {
      type: String
    }
  },

  computed: {
    ...appComputed
  },

  methods: {
    toggleSidebar () {
      const wrapper = document.getElementById('wrapper')

      wrapper.classList.toggle('toggled')
      wrapper.classList.add('transitioning')

      setTimeout(() => {
        wrapper.classList.remove('transitioning')
      }, 300)
    }
  }
}
</script>
