<template>
  <div id="app">
    <div class="authentication">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
