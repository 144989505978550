import { make, get, call } from 'vuex-pathify'

export const helperComputed = get('app', ['sessionTimedOut'])
export const helperMethods = call('app', ['resetSessionTimedOut'])

export default {
  namespaced: true,

  state: {
    csrf: false,
    sessionTimedOut: {
      timedOut: false,
      intended: null
    }
  },

  actions: {
    ...make.actions('csrf'),
    ...make.actions('sessionTimedOut'),

    resetSessionTimedOut ({ dispatch }) {
      dispatch('setSessionTimedOut', {
        timedOut: false,
        intended: null
      })
    }
  },

  mutations: {
    ...make.mutations('csrf'),
    ...make.mutations('sessionTimedOut')
  }
}
