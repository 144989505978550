import { Fragment } from 'vue-fragment'
import Vue from 'vue'
import AppHeader from '@/components/app/AppHeader'
import VueMultiselect from 'vue-multiselect'
import Flatpickr from 'vue-flatpickr-component'

Vue.component('app-header', AppHeader)
Vue.component('multiselect', VueMultiselect)
Vue.component('fragment', Fragment)
Vue.component('flatpickr', Flatpickr)
