import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/settings',
    name: 'settings',
    meta: {
      can: 'read settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/SettingsList')
  },
  {
    path: '/settings/screen-tags',
    name: 'settings.screen-tags',
    meta: {
      can: 'read settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/ScreenTags')
  },
  {
    path: '/settings/media-owner-tags',
    name: 'settings.media-owner-tags',
    meta: {
      can: 'read settings'
    },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/MediaOwnerTags')
  },
])
