<template>
  <div id="sidebar-wrapper">
    <div class="top">
      <img class="logo" src="@/assets/img/logo/primary-light.svg" alt="Codium" />
    </div>

    <div class="middle">
      <ul class="nav">
        <sidebar-item :to="{ name: 'dashboard' }" icon="home">Dashboard</sidebar-item>
        <sidebar-item v-if="$can('read camera wall')" :to="{ name: 'camera-wall' }" icon="th">Camera Wall</sidebar-item>
        <sidebar-item v-if="$can('read screens')" :to="{ name: 'screens' }" icon="tv-alt">Screens</sidebar-item>
        <sidebar-item v-if="$can('read media owners')" :to="{ name: 'media-owners' }" icon="city">Media Owners</sidebar-item>
        <sidebar-item v-if="$can('read users')" :to="{ name: 'users' }" icon="users">User Management</sidebar-item>
        <sidebar-item v-if="$can('read reports')" :to="{ name: 'reports.screen' }" icon="users">Reports</sidebar-item>
        <sidebar-item v-if="$can('read settings')" :to="{ name: 'settings' }" icon="cog">Settings</sidebar-item>
      </ul>
    </div>
  </div>
</template>

<script>
import SidebarItem from './AppSidebarItem'

export default {
  components: {
    SidebarItem
  }
}
</script>
