import { makeResource } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('user/settings', {
  resource () {
    return {
      id: null,
      url: null,
      date: null
    }
  }
})
