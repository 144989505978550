import { applyRouteMeta } from '@codium/codium-vue'
import toNumber from 'lodash/toNumber'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/media-owners',
    name: 'media-owners',
    meta: {
      can: 'read media owners'
    },
    component: () => import(/* webpackChunkName: "media-owners" */ '@/views/media-owners/MediaOwnersList')
  },
  {
    path: '/media-owners/add',
    name: 'media-owners.add',
    meta: {
      can: 'write media owners'
    },
    component: () => import(/* webpackChunkName: "media-owners" */ '@/views/media-owners/MediaOwnersAdd')
  },
  {
    path: '/media-owners/:mediaOwnerId',
    props: ({ params }) => ({ mediaOwnerId: toNumber(params.mediaOwnerId) }),
    component: () => import(/* webpackChunkName: "media-owners" */ '@/views/media-owners/MediaOwnersSingle'),
    children: [
      {
        path: 'details',
        name: 'media-owners.single.details',
        meta: {
          can: 'write media owners'
        },
        component: () => import(/* webpackChunkName: "media-owners" */ '@/views/media-owners/MediaOwnersSingleDetails')
      },
      {
        path: 'screens',
        name: 'media-owners.single.screens',
        meta: {
          can: 'read media owners'
        },
        component: () => import(/* webpackChunkName: "media-owners" */ '@/views/media-owners/MediaOwnersScreensList')
      },
    ]
  }
])
