export default (store) => {
  const ability = store.getters['auth/ability']

  const mapAbilities = (abilities) => {
    return abilities.map(({ name: action }) => ({ action }))
  }

  ability.update(mapAbilities(store.state.auth.user.abilities || []))

  return store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'auth/SET_USER':
        ability.update(mapAbilities(store.state.auth.user.abilities || []))
        break
      case 'auth/RESET_USER':
        ability.update([])
        break
    }
  })
}
