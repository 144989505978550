import { makeResource } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('screens/images', {
  namespaced: true,

  resource () {
    return {
      id: null,
      screen_name: null,
      taken: null,
    }
  }
})
