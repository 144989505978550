import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import then use library.add() to add your Font Awesome icons.
library.add(
  //
)

Vue.component('font-awesome', FontAwesomeIcon)
