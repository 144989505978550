import { makeResource } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('screens/faults', {
  namespaced: true,

  resource () {
    return {
      id: null,
      start_time: null,
      end_time: null,
      duration: null,
      type: null,
    }
  }
})
