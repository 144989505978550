import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/my',
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/My'),
    children: [
      {
        path: 'account',
        name: 'my.account',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/MyAccount')
      },
      {
        path: 'security',
        name: 'my.security',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/MySecurity')
      }
    ]
  }
])
