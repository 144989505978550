import { makeModule } from '@codium/codium-vue'
import { storeModule as mediaOwnerTags } from './lists/media-owner-tags'
import { storeModule as screenTags } from './lists/screen-tags'
import { storeModule as mediaOwners } from './lists/media-owners'

export const storeModule = makeModule('lists', {
  modules: {
    mediaOwnerTags,
    screenTags,
    mediaOwners
  }
})
