import Vue from 'vue'
import router from './router'
import store from './vuex/store'
import App from './App.vue'
import Codium from '@codium/codium-vue'
import Chameleon from '@chameleon/chameleon-vue'

/**
 * Import other dependencies.
 */
import '@/mixins'
import '@/filters'
import '@/utils/font-awesome'
import configureValidation from '@/utils/validation'
import '@/utils/components'
import 'bootstrap'

/**
 * Import theme SCSS.
 */
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/scss/app.scss'

Vue.config.productionTip = false

Vue.use(Codium)
Vue.use(Chameleon)

configureValidation()

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
