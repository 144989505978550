import { makeResource } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('screens/brightnessLogs', {
  namespaced: true,

  resource () {
    return {
      id: null,
      date: null,
      auto_bright_enabled: null,
      brightness: null,
      environment_brightness: null,
    }
  }
})
