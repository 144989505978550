import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('mediaOwners', {
  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      tags: mapped([])
    }
  }
})
