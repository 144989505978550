import { makeResource } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('cameras', {
  resource () {
    return {
      id: null,
      name: null,
      screen: null,
    }
  }
})
