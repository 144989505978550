<template>
  <router-link v-bind="$attrs" custom v-slot="{ href, isActive, isExactActive, navigate }">
    <li class="nav-item" :class="{ active: exact ? isExactActive : isActive }" @click="navigate">
      <a class="nav-link" v-bind="{ href }">
        <i class="far fa-fw" :class="`fa-${icon}`"></i>

        <span><slot/></span>
      </a>
    </li>
  </router-link>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    icon: String,
    exact: Boolean
  }
}
</script>
