import { applyRouteMeta } from '@codium/codium-vue'
import toNumber from 'lodash/toNumber'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/users',
    name: 'users',
    meta: {
      can: 'read users'
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersList')
  },
  {
    path: '/users/add',
    name: 'users.add',
    meta: {
      can: 'write users'
    },
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersAdd')
  },
  {
    path: '/users/:userId',
    component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingle'),
    props: ({ params }) => ({ userId: toNumber(params.userId) }),
    children: [
      {
        path: '/users/:userId',
        name: 'users.single',
        meta: {
          can: 'read users'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleDetails')
      },
      {
        path: 'security',
        name: 'users.single.security',
        meta: {
          can: 'read users'
        },
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSingleSecurity')
      }
      // {
      //   path: 'access-permissions',
      //   name: 'users.single.permissions',
      //   meta: {
      //     can: 'read users'
      //   },
      //   component: () => import(/* webpackChunkName: "users" */ '@/views/users/UsersSinglePermissions')
      // }
    ]
  }
])
