import { applyRouteMeta } from '@codium/codium-vue'

// dynamically import all routes inside the './routes' folder
const context = require.context('./routes', true, /\/.*.js/)
const routes = context.keys().map(context).reduce((routes, module) => [...routes, ...module.default], [])

export default [
  ...routes,

  ...applyRouteMeta({
    template: 'app',
    requiresAuth: true
  }, [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.js')
    }
  ]),
  ...applyRouteMeta({
    template: 'error',
    sometimesAuth: false
  }, [
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "errors" */ '@/views/errors/404')
    }
  ])
]
