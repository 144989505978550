import { makeResource } from '@codium/codium-vue'
import { storeModule as screenTags } from './settings/screen-tags'
import { storeModule as mediaOwnerTags } from './settings/media-owner-tags'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('settings', {
  modules: {
    screenTags,
    mediaOwnerTags
  },
  storeModule: module,
  resource () {
    return {
    }
  }
})
