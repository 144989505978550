import { applyRouteMeta } from '@codium/codium-vue'

export default applyRouteMeta({
  template: 'app',
  requiresAuth: true
}, [
  {
    path: '/reports',
    name: 'reports',
    meta: {
      can: 'read reports'
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/Reports'),
    children: [
      {
        path: 'users',
        name: 'reports.user',
        meta: {
          can: 'read users',
          isA: 'admin'
        },
        component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/UserReport')
      },
      {
        path: 'screens',
        name: 'reports.screen',
        meta: {
          can: 'read screens',
        },
        component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/ScreenReport')
      }

    ]
  }
])
