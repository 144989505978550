import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('abilities', {
  resource () {
    return {
      id: mapped(null),
      name: mapped(null),
      title: mapped(null),
      group: mapped(null)
    }
  }
})
