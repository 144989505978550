import { http } from '@codium/codium-vue'
import { toast } from '@chameleon/chameleon-vue'
import router from '@/router'
import store from '@/vuex/store'

http.defaults.headers.post['Content-Type'] = 'application/json'
http.defaults.headers.put['Content-Type'] = 'application/json'
http.defaults.headers.Accept = 'application/json'

http.interceptors.request.use(async (request) => {
  if (request.data === undefined && ['post', 'put'].includes(request.method.toLowerCase())) {
    request.data = {}
  }

  // For POST, PUT, and DELETE requests make a CSRF preflight if we have not already
  // made one for this page load.
  if (
    ['post', 'put', 'delete'].includes(request.method.toLowerCase()) &&
    store.state.app.csrf === false &&
    request.csrfPreflight === undefined
  ) {
    await http.get('sanctum/csrf-cookie', {
      csrfPreflight: true
    })

    store.dispatch('app/setCsrf', true)
  }

  return request
})

http.interceptors.response.use(null, async (error) => {
  if (error.response && error.response.status === 401) {
    const route = Object.assign({ meta: {} }, router.currentRoute)

    if (
      error.response.config.authorizePreflight === true ||
      router.currentRoute.name === 'login'
    ) {
      return Promise.reject(error)
    }

    if (
      route.name !== 'login' &&
      route.name !== 'logout' &&
      route.name !== null &&
      route.meta.requiresAuth === true
    ) {
      store.dispatch('auth/resetUser')
      store.dispatch('app/setSessionTimedOut', {
        timedOut: true,
        intended: router.currentRoute.path
      })
    }
    router.replace({ name: 'login' }).catch(() => {})

    return Promise.reject(error)
  }

  if (error.response && error.response.request.responseType === 'blob') {
    const read = (file) => {
      const reader = new FileReader()

      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort()

          reject(new Error('Problem parsing file'))
        }

        reader.onload = () => {
          resolve(reader.result)
        }

        reader.readAsText(file)
      })
    }

    const body = await read(error.response.data)

    error.response.data = JSON.parse(body)
  }

  // For 403s we'll throw a blanket error notification and redirect them back to the dashboard.
  if (error.response) {
    if (error.response.status === 403 && error.response.config.throwForbiddens !== true) {
      toast('You do not have permission to do that.', 'danger')

      router.replace({ name: 'dashboard' })
    } else if (error.response.status === 500) {
      toast('We encountered a server error. We have been alerted and will investigate.', 'danger')
    }
  }

  return Promise.reject(error)
})

export default http

/**
 * Generate URL to API.
 *
 * @return string
 */
export const apiUrl = (path) => {
  if (path === undefined) {
    return process.env.VUE_APP_API_URL
  }

  return `${process.env.VUE_APP_API_URL}/${path}`
}
