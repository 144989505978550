import { makeResource, mapped } from '@codium/codium-vue'

export const { storeModule, helperComputed, helperMethods, stateMapper } = makeResource('emailTemplates', {
  resource () {
    return {
      id: mapped(null),
      content: mapped(null),
      type_id: mapped(null),
    }
  }
})
