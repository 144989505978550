import { has } from 'lodash'
import { toast } from '@chameleon/chameleon-vue'
import store from '@/vuex/store'

const DEFAULT_REDIRECT = { name: 'dashboard' }
const ROLE_REDIRECT = {
  admin: { name: 'dashboard' }
}

export default (to, from, next) => {
  const { user } = store.state.auth
  const can = store.getters['auth/can']
  const cannot = store.getters['auth/cannot']

  if (has(to.meta, 'can')) {
    if (
      (typeof to.meta.can === 'function' && to.meta.can(can, to, from) === false) ||
      (typeof to.meta.can !== 'function' && cannot(to.meta.can))
    ) {
      toast('You do not have the necessary permission to perform this action.', 'danger')

      if (ROLE_REDIRECT[user.role.name]) {
        return next(ROLE_REDIRECT[user.role.name])
      }

      return next(DEFAULT_REDIRECT)
    }
  }

  next()
}
